/*--------------------------------------------------------------------------*
 * ハンバーガーメニュー
 *--------------------------------------------------------------------------*/
$(function(){
    if ($(window).width() <= 1024) {
        $('[data-privacy-trigger]').on('click', function(){
            $(this).toggleClass('active');
            var target = $(this).attr('href');
            $(target).toggleClass('visible');
            return false;
        });
    }
});
